import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In this post, I will be using the same files as my previous tutorial - `}<a parentName="p" {...{
        "href": "https://fabiorosado.github.io/vue/weather-app/"
      }}>{`Creating a weather app: using Vue and Axios`}</a>{` - because what I will show you is tightly tied to the previous tutorial.`}</p>
    <p><em parentName="p">{`You might be wondering, why I am writing this post.`}</em></p>
    <p>{`The previous tutorial was an introduction to Vue and Axios, but the location was hardcoded in the Vue app. If you want a working weather app you will only be able to get the weather in London and not your city.`}</p>
    <p><em parentName="p">{`Do you need to read the previous tutorial to follow this one?`}</em></p>
    <p>{`No. You can just `}<a parentName="p" {...{
        "href": "https://github.com/FabioRosado/fabiorosado.github.io/tree/master/assets/files/weather-tutorial"
      }}>{`get the needed files`}</a>{` and start where we left off. You will also need to get an `}<a parentName="p" {...{
        "href": "https://openweathermap.org/api"
      }}>{`API key from OpenWeatherMap`}</a>{` so you can get the weather information.`}</p>
    <h1 {...{
      "id": "geolocation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#geolocation",
        "aria-label": "geolocation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Geolocation`}</h1>
    <p>{`HTML5 offers a Geolocation API so you can get the location of a user - in this case, you. Geolocation is also available on 93% of the browsers according to `}<a parentName="p" {...{
        "href": "https://caniuse.com/#search=geolocation"
      }}>{`Can I use`}</a>{`, which means that most browsers support this function.`}</p>
    <p>{`When using geolocation you need to know a few things:`}</p>
    <ul>
      <li parentName="ul">{`The user needs to approve the request to share the location`}</li>
      <li parentName="ul">{`Geolocation is more accurate for devices with GPS`}</li>
      <li parentName="ul">{`Geolocation API will only work on secure contexts such as HTTP (some browsers won't ask for the permission and get decline straight away).`}</li>
    </ul>
    <p>{`Because geolocation is dependent on the user accepting to share the location, we should have a strategy when our app can't get the location. `}</p>
    <p>{`A final thing worth to mention. I have tried a few ways to make geolocation work with Vue. A lot of them failed, but in the end, I was able to make it work by creating a function to check if geolocation is enabled. If it was, then a new function would be called and we would get the latitude and longitude details.`}</p>
    <h1 {...{
      "id": "vuejs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#vuejs",
        "aria-label": "vuejs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VueJs`}</h1>
    <p>{`Our VueJs app was left in this state:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let weatherApp = new Vue({
  el: '#app',
  data: {
    currentTemp: '',
    minTemp: '',
    maxTemp:'',
    sunrise: '',
    sunset: '',
    pressure: '',
    humidity: '',
    wind: '',
    overcast: '', 
    icon: ''
  },
  methods: {
    getWeather() {
      let url = "http://api.openweathermap.org/data/2.5/weather?q=London&units=metric&APPID={Your API Key}";
      axios
        .get(url)
        .then(response => {
          this.currentTemp = response.data.main.temp;
          this.minTemp = response.data.main.temp_min;
          this.maxTemp = response.data.main.temp_max;
          this.pressure = response.data.main.pressure;
          this.humidity = response.data.main.humidity + '%';
          this.wind = response.data.wind.speed + 'm/s';
          this.overcast = response.data.weather[0].description;
          this.icon = "images/" + response.data.weather[0].icon.slice(0, 2) + ".svg";
          this.sunrise = new Date(response.data.sys.sunrise*1000).toLocaleTimeString("en-GB").slice(0,4);
          this.sunset = new Date(response.data.sys.sunset*1000).toLocaleTimeString("en-GB").slice(0,4);
      })
      .catch(error => {
        console.log(error);
      });
    },
  },
  beforeMount() {
    this.getWeather();
  },
});
`}</code></pre>
    <p>{`Immediately, we can see that the method `}<inlineCode parentName="p">{`getWeather`}</inlineCode>{` will need to be refactored because the API URL is hardcoded to display the weather in London only. The rest of the function seems to be pretty good since we are just assigning values obtained from the API. If we change the URL bit everything will stay the same.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`    getWeather(url) {
      axios
        .get(url)
        .then(response => {
          this.currentTemp = response.data.main.temp;
          this.minTemp = response.data.main.temp_min;
          this.maxTemp = response.data.main.temp_max;
          this.pressure = response.data.main.pressure;
          this.humidity = response.data.main.humidity + '%';
          this.wind = response.data.wind.speed + 'm/s';
          this.overcast = response.data.weather[0].description;
          this.icon = "images/" + response.data.weather[0].icon.slice(0, 2) + ".svg";
          this.sunrise = new Date(response.data.sys.sunrise*1000).toLocaleTimeString("en-GB").slice(0,4);
          this.sunset = new Date(response.data.sys.sunset*1000).toLocaleTimeString("en-GB").slice(0,4);
      })
      .catch(error => {
        console.log(error);
      });
    },
`}</code></pre>
    <p>{`The variable `}<inlineCode parentName="p">{`URL`}</inlineCode>{` was deleted and we are simply adding a parameter `}<inlineCode parentName="p">{`url`}</inlineCode>{` to the `}<inlineCode parentName="p">{`getWeather`}</inlineCode>{` function. Now, we can get our hands into creating methods for the geolocation and getting the URL.`}</p>
    <p>{`First, we will add two constant variables to the top of our main.js file(lines 1 and 2):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const API = 'http://api.openweathermap.org/data/2.5/weather?units=metric'
const KEY = '&APPID={Your API Key Here}'
`}</code></pre>
    <p>{`Now inside the VueJs app we will create two methods:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`geolocation()`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`buildUrl(position)`}</inlineCode></li>
    </ul>
    <p>{`As I said before, geolocation needs to have a function as a parameter if we want to do something with the latitude and longitude obtained through the browser. The method `}<inlineCode parentName="p">{`buildUrl(position)`}</inlineCode>{` is just that, the position parameter will be used to extract these two things.`}</p>
    <h2 {...{
      "id": "methods-for-the-geolocation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#methods-for-the-geolocation",
        "aria-label": "methods for the geolocation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Methods for the geolocation`}</h2>
    <p>{`The methods will be quite simple to understand, first, we will call the `}<inlineCode parentName="p">{`getCurrentPosition`}</inlineCode>{` method from the Geolocation API. The two parameters passed are callback functions - one if it was successful(`}<inlineCode parentName="p">{`this.buildUrl`}</inlineCode>{`) and one if an error was returned(`}<inlineCode parentName="p">{`this.geoError`}</inlineCode>{`).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`    geolocation() {
      navigator.geolocation.getCurrentPosition(this.buildUrl, this.geoError);
    },
    buildUrl(position) {
      const lat = position.coords.latitude;
      const lon = position.coords.longitude;

      this.getWeather(API + '&lat=' + lat + '&lon=' + lon + KEY);
    },
    geoError(error) {
      this.getWeather(API + '&lat=0&lon=0' + KEY);
    }
`}</code></pre>
    <p>{`As you can see the methods are pretty straightforward, we are simply building the API URL depending on how successful it was to get the user location, if not then we will just use 0,0 as location. `}<em parentName="p">{`Bonus points if you figure out where it is.`}</em></p>
    <p>{`One final change we need to do to the VueJs app is replacing the method being called on `}<inlineCode parentName="p">{`beforeMount()`}</inlineCode>{`. Instead of calling `}<inlineCode parentName="p">{`this.getWeather()`}</inlineCode>{` we need to call `}<inlineCode parentName="p">{`this.geolocation()`}</inlineCode>{`.`}</p>
    <p>{`Our `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` file will look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const API = 'http://api.openweathermap.org/data/2.5/weather?units=metric'
const KEY = '&APPID=9ef28fe3735a820928decffcf48f5c3f'

let weatherApp = new Vue({
  el: '#app',
  data: {
    currentTemp: '',
    minTemp: '',
    maxTemp:'',
    sunrise: '',
    sunset: '',
    pressure: '',
    humidity: '',
    wind: '',
    overcast: '',
    icon: '',
    location: ''
  },
  methods: {
    getWeather(url) {
      axios
        .get(url)
        .then(response => {
          this.currentTemp = response.data.main.temp;
          this.minTemp = response.data.main.temp_min;
          this.maxTemp = response.data.main.temp_max;
          this.pressure = response.data.main.pressure;
          this.humidity = response.data.main.humidity + '%';
          this.wind = response.data.wind.speed + 'm/s';
          this.overcast = response.data.weather[0].description;
          this.icon = "images/" + response.data.weather[0].icon.slice(0, 2) + ".svg";
          this.sunrise = new Date(response.data.sys.sunrise*1000).toLocaleTimeString("en-GB").slice(0,5);
          this.sunset = new Date(response.data.sys.sunset*1000).toLocaleTimeString("en-GB").slice(0,5);
      })
      .catch(error => {
        console.log(error);
      });
    },
    geolocation() {
      navigator.geolocation.getCurrentPosition(this.buildUrl, this.geoError);
    },
    buildUrl(position) {
      const lat = position.coords.latitude;
      const lon = position.coords.longitude;

      this.getWeather(API + '&lat=' + lat + '&lon=' + lon + KEY);
    },
    geoError(error) {
      this.getWeather(API + '&lat=0&lon=0' + KEY);
    }
  },
  beforeMount() {
    this.geolocation();
  },
});
`}</code></pre>
    <h1 {...{
      "id": "conclusion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#conclusion",
        "aria-label": "conclusion permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conclusion`}</h1>
    <p>{`This is a very brief tutorial on how to use the Geolocation API with VueJs. The reason why I decided to write this tutorial was because I had plenty of issues trying to make it work on my `}<a parentName="p" {...{
        "href": "https://github.com/FabioRosado/landing-page"
      }}>{`Quote and Weather landing page`}</a>{` so hopefully, this will help you in case you are stuck.`}</p>
    <p>{`Finally, we should probably change the app so it shows the current location in order for our users to know which weather data did they get. I will leave this challenge for you to complete - you might have to fix the CSS a bit as well if you add the location.`}</p>
    <p><em parentName="p">{`You can `}<a parentName="em" {...{
          "href": "https://github.com/FabioRosado/fabiorosado.github.io/tree/master/assets/files/weather-tutorial%20part%20II"
        }}>{`get the updated main.js file here.`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      